import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Mark Sargeant Customer Story - Rotaready"
          description="Learn how Rotaready has helped Mark Sargeant save huge amount of time on admin heavy tasks and make payroll more accurate than ever."
          url="customer-stories/hospitality-mark-sargeant"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Interior of Mark Sargeant restaurant"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Mark Sargeant logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Learn how Rotaready has helped Mark Sargeant save huge amount of time on admin heavy tasks and make payroll more accurate than ever." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="7" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="143" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="676%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="14 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Mark Sargeant" />
              </Content>

              <Content>
                <TextBlock text="From Head Chef of Gordon Ramsay at Claridges, to owner and operator of his own restaurant group; Michelin-starred chef Mark Sargeant has certainly made a name for himself in the hospitality industry." />
              </Content>

              <Content>
                <TextBlock text="With a mix of fine dining restaurants, pubs and takeaway restaurants it's safe to say each of his sites has its own unique charm and target audience; yet they were all facing similar challenges." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Staff scheduling... with spreadsheets" />
              </Content>

              <Content>
                <TextBlock text="All four sites were building staff rotas using spreadsheets and managing their costs in a reactive way. At the end of each month, the accounts team analysed the costs to approximate how busy each day had been; they used this to decide how many staff to schedule on future rotas. Building rotas based on past demand as opposed to future demand meant that the balance between staffing levels and footfall was often slightly ‘off'. This left a lot of scope to improve the accuracy of staff scheduling and achieve the optimal labour cost percentage as a result." />
              </Content>

              <Content>
                <TextBlock text="Besides building more accurate staff rotas, the the group wanted a solution that would give staff greater clarity and visibility over their scheduled hours, and managers greater visibility and control over staff costs." />
              </Content>

              <Content>
                <TextBlock text="None of this could be achieved using spreadsheets, so the group looked to make the jump from its existing approach to staff scheduling." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A game changing recommendation" />
              </Content>

              <Content>
                <TextBlock text="Mark Sargeant Group looked into a number of software providers and Rotaready came recommended from one of their industry peers. After a demo, it quickly became clear Rotaready was far superior to the other providers the group had considered. So, after one month of trialling the product the group made the decision to roll out Rotaready across all four sites and were fully set up within two weeks." />
              </Content>

              <Content>
                <TextBlock text="Rotaready has become an irreplaceable tool for the entire business. The mobile app means all staff now have their schedule at their fingertips. Managers are finding the day to day running of their pubs & restaurants significantly easier, as they can spend less time on admin heavy tasks. And for HQ it has made payroll, monitoring holiday allowances and weekly sign-off a pleasure rather than a chore." />
              </Content>

              <Content>
                <Blockquote
                  text="Working with Rotaready has made life easier for the entire business. Scheduling staff time is easy, payroll is more accurate than ever and we have saved a huge amount of time on admin heavy tasks. We're excited to continue working with the team in the future."
                  attribution="Jack Nimmons - Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="Not only has Rotaready enabled Mark Sargeant Group to take its workforce management to the next level, the time and cost savings that have come with it have resulted in an ROI of over 675%." />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="EPOS and expansion" />
              </Content>

              <Content>
                <TextBlock text="Having opened 3 new sites at the end of 2019 Mark Sargeant are looking to further streamline the systems they use across the group. Part of this process is implementing a new till solution that integrates with Rotaready." />
              </Content>

              <Content>
                <TextBlock text="This integration will allow Mark Sargeant Group to view key business metrics, such as number of covers and daily sales, from one dashboard. It will also help strengthen the sales forecasts generated by Rotaready, and as a result help the group make even more informed scheduling decisions across their current and future sites." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline.childImageSharp.gatsbyImageData}
                alt="Bowl of mussels"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_marksargeant"
                label="rotaready.com/customer-stories/hospitality-mark-sargeant"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Mark-Sargeant.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-marksargeant.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-marksargeant.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline: file(relativePath: { eq: "customer-stories/inline-marksargeant.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
